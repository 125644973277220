import React from 'react';
import { Layout } from '../../shared/ui';

const Directors = () => {
  return (
    <Layout transparentNav topMargin={false}>
      <div className=" flex justify-center items-center min-h-[100vh] font-jakarta flex-col w-full bg-[url('../../assets/director-bg.jpg')] bg-cover bg-center">
        <h2 className=" text-white uppercase font-bold text-[clamp(30px,10vw,55px)] lg:text-[clamp(30px,8vw,70px)] xl:text-[clamp(30px,10vw,6vw)]">
          Coming Soon
        </h2>
      </div>
    </Layout>
  );
};

export default Directors;
